<template>
  <v-container>
    <v-card class="px-5 py-3">
      <div class="d-flex">
        <v-tabs class="mb-3" v-if="!battleServer">
          <v-tab @click="switchTab(2)">Активные</v-tab>
          <v-tab @click="switchTab(3)">Не активные</v-tab>
        </v-tabs>
        <v-btn @click="exportExcel" color="primary">
          Экспортировать
        </v-btn>
      </div>


      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :single-select="true"
        item-key="id"
        sort-by="category"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalItems"
        :footer-props="footerProps"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="edit(item)" v-if="activeTab == 2">
            mdi-pencil
          </v-icon>
          <v-icon
            small
            class="mr-2"
            color="red darken-2"
            @click="deleteItem(item)"
            v-if="activeTab == 2"
          >
            mdi-delete
          </v-icon>
          <v-icon
            small
            class="mr-2 ml-3"
            color="green darken-2"
            @click="deleteItem(item)"
            v-if="activeTab == 3"
          >
            mdi-power
          </v-icon>
        </template>
        <template v-slot:[`item.is_popular`]="{ item }">
          <v-checkbox
            v-model="item.is_popular"
            @change="changePopular(item)"
            v-if="activeTab == 2"
            :disabled="disabledBtn"
          >
          </v-checkbox>
          <v-icon style="margin-left: 2px" v-if="activeTab == 3">mdi-cancel</v-icon>
        </template>
        <template v-slot:[`item.has_widget`]="{ item }">
          <v-checkbox
            v-model="item.has_widget"
            @change="changeWidget(item)"
            v-if="activeTab == 2"
            :disabled="disabledBtn"
          >
          </v-checkbox>
          <v-icon style="margin-left: 2px" v-if="activeTab == 3">mdi-cancel</v-icon>
        </template>
        <template v-slot:[`item.custom_rating`]="{ item }">
          <v-rating
            background-color="warning"
            color="warning"
            length="5"
            readonly
            :value="item.custom_rating"
          ></v-rating>
        </template>
      </v-data-table>


      <v-btn :loading="btn_Loading" :disabled="btn_Loading" color="primary" class="ma-3" @click="create"> Добавить компанию </v-btn>
    </v-card>
  </v-container>
</template>

<script>
import { CompaniesApiUrls } from "@/services/accommodationRequests/companies.api.js";
import store from "@/store/auth/index";

export default {
  name: "Companies",
  data() {
    return {
      disabledBtn: false,
      activeTab: 2,
      battleServer: false,
      footerProps: {
        "items-per-page-options": [
          {
            text: "10",
            value: 10,
          },
          {
            text: "20",
            value: 20,
          },
          {
            text: "30",
            value: 30,
          },
          {
            text: "Все",
            value: 99999999999,
          },
        ],
      },
      totalItems: 0,
      options: {},
      firstWatcherTrigger: false,
      e1: 1,
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: "Название",
          align: "start",
          sortable: false,
          value: "name",
          class: "table-title",
        },
        {
          text: "Действия",
          value: "actions",
          sortable: false,
          class: "table-title",
        },
        {
          text: "Рейтинг",
          value: "custom_rating",
          sortable: false,
          class: "table-title",
        },
        {
          text: "Популярный?",
          value: "is_popular",
          sortable: false,
          class: "table-title",
        },
        {
          text: "Виджет?",
          value: "has_widget",
          sortable: false,
          class: "table-title",
        },
      ],
      items: [],
      loading: false,
      btn_Loading: false, 
    };
  },
  watch: {
    options: {
      handler() {
        if (!this.firstWatcherTrigger) {
          this.firstWatcherTrigger = true;
          return;
        }
        this.loadRow();
      },
      deep: true,
    },
  },
  created() {
    this.loadRow();
  },
  methods: {
    async exportExcel(){
      try{
        this.btn_Loading = true;
        let res = await CompaniesApiUrls.exportCompanies();
        console.log(res);
        var blob = new Blob([res], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;Content-Disposition: attachment"
        });
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(blob, "companies.xlsx");
        } else {
          if (window.innerHeight < 500 || window.innerWidth < 500) {
            var ios =
              !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent);
            var isSafari =
              navigator.vendor &&
              navigator.vendor.indexOf("Apple") > -1 &&
              navigator.userAgent &&
              navigator.userAgent.indexOf("CriOS") == -1 &&
              navigator.userAgent.indexOf("FxiOS") == -1;
            if (ios && !isSafari) {
              toast.error("Вы можете скачать отчеты в Safari");
            } else {
              var reader = new FileReader();
              reader.fileName = "companies.xlsx";
              var out = new File([res], "companies.xlsx", {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;Content-Disposition: attachment; filename='companies.xlsx'"
              });
              reader.onload = function(e) {
                window.location.href = reader.result;
              };
              reader.readAsDataURL(out);
            }
          } else {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "companies.xlsx");
            if (typeof link.download === "undefined") {
              link.setAttribute("target", "_blank");
            }
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);
            }, 100);
          }
        }
      }
      catch(e) {
        console.log(e);
      }
      finally{
        this.btn_Loading = false;
      }
    },
    async switchTab(int) {
      this.loading = true;
      let params = {
        "page[number]": this.options.page || 1,
        "page[size]": this.options.itemsPerPage || 10,
      };
      if(int == 2){
        this.activeTab = 2
        params.deleted = false
      }
      else{
        this.activeTab = 3
        params.deleted = true
      }
      let response = await CompaniesApiUrls.getCompanies(params)
      this.totalItems = response.meta.pagination.count;
      this.items = response.results;
      this.loading = false;
    },
    async changePopular(item) {
      this.disabledBtn = true;
      await CompaniesApiUrls.setCompany(item.id, { ...item, is_popular: item.is_popular })
      this.switchTab(this.activeTab);
      this.disabledBtn = false;
    },
    async changeWidget(item) {
      this.disabledBtn = true;
      await CompaniesApiUrls.setCompany(item.id, { ...item, has_widget: item.has_widget })
      if(store.getters.getCompany == item.id) store.commit('setHasWidget', item.has_widget)
      this.switchTab(this.activeTab);
      this.disabledBtn = false;
    },
    async loadRow() {
      this.loading = true;
      let params = {
        "page[number]": this.options.page || 1,
        "page[size]": this.options.itemsPerPage || 10,
        deleted: false, 
      };
      let response = await CompaniesApiUrls.getCompanies(params)
      this.totalItems = response.meta.pagination.count;
      this.items = response.results;
      this.loading = false;
    },
    create() {
      this.$router.push("/company");
    },
    edit(item) {
      this.$router.push("/company/" + item.id);
    },
    async deleteItem(item) {
      await CompaniesApiUrls.deleteCompany(item.id)
      this.switchTab(this.activeTab);
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: orange !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
</style>
